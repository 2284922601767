import {Button, Card, Typography, Dropdown, Space, Menu, Modal, Form, Input, Spin} from 'antd';
import {CheckCircleOutlined, DownOutlined} from '@ant-design/icons';
import React, {useState, useEffect} from 'react';
import {useDataProvider, useNotify, usePermissions, useQuery} from 'react-admin';
import {ExtendedDataProvider} from "../../types";
import ReactJson from "react-json-view";
import Editor from '@monaco-editor/react';
import {NotFoundComponent} from "@src/components/Common/utils";

const { Title } = Typography;

const ManageServiceConfigs = ({ record }: { record: any }) => {
  const { permissions } = usePermissions();
  const [menu, setMenu] = useState<any>(<Menu items={[]}/>);
  const [version, setVersion] = useState<any>(0);
  const [config, setConfig] = useState<any>({});
  const [updateChangeForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataProvider: ExtendedDataProvider = useDataProvider();
  const notify = useNotify();

  const { data: serviceConfigs, loaded, error, total, refetch } = useQuery({
      type: 'getList',
      resource: 'serviceowner_service_configs',
      payload: {
          pagination: { page: 1, perPage: 20 },
          sort: { field: 'version', order: 'asc' },
          filter: { service_id: record.id }
      }
  });

  const handleVersionChange = (e:any) => {
    const sc = serviceConfigs.filter((sc: any) => sc.version == e.key)[0]
    setVersion(sc.version)
    setConfig(sc.config)
  }

  useEffect(() => {
    if (!serviceConfigs)
      return

    if (serviceConfigs != 0) {
      setVersion(serviceConfigs.length != 0?serviceConfigs[0].version:0)
      setConfig(serviceConfigs.length != 0?serviceConfigs[0].config:{})

      setMenu(
        <Menu
          onClick={handleVersionChange}
          items={serviceConfigs.map((sData: any) => {
            return (
              {
                key: sData.version,
                label: sData.version
              }
            )
          })}
        />
      );
    }
  }, [serviceConfigs]);


  const handleOk = () => {
    setIsModalOpen(false);
    refetch();
  };

  const onSubmitCreateServiceConfig = (values: any) => {
      const data: any = { service: values.service }
      const config = values?.config
      if(config) {
          if(!(config==="" || config=='{}'))
              data['config'] = JSON.parse(config)
      }
      if(!('config' in data)) {
          notify("Empty dictionary is not accepted", 'warning')
          return
      }

    dataProvider.create('serviceowner_service_configs', { data: data })
      .then(response => {
        notify('New Service Config is created')
        setIsModalOpen(false);
        refetch();
      }).catch(error => {
        notify("Something went wrong", "warning")
    })
  }

  return (
      <>
          {
              loaded?
                  <Card style={{ padding: '20px' }}>
                      {
                          total == 0 ?
                              NotFoundComponent
                              :
                              <>
                                  <Space>Version:
                                      <Dropdown overlay={menu} trigger={['click']}>
                                          <a onClick={(e) => e.preventDefault()}>
                                              <Button>
                                                  <Space><Title level={5}>{version}</Title><DownOutlined /></Space>
                                              </Button>
                                          </a>
                                      </Dropdown>
                                  </Space>
                                  <ReactJson
                                      style={{ padding: '20px' }}
                                      src={config}
                                      displayDataTypes={false}
                                      name={null}
                                      displayArrayKey={false}
                                      displayObjectSize={false}
                                      collapsed={true}
                                  />
                              </>
                      }
                      {
                          permissions === "ADMIN" &&
                          <Button onClick={() => {setIsModalOpen(true)}} style={{ margin: '10px' }} type="primary" icon={<CheckCircleOutlined />} > Create a new Config</Button>
                      }
      <Modal title="New Service Config"
             visible={isModalOpen}
             onOk={handleOk}
             footer={[
               <Button key="back" onClick={() => setIsModalOpen(false)}>
                 Cancel
               </Button>,
               <Button key="submit" type="primary" onClick={updateChangeForm.submit}>
                 Submit new config
               </Button>
             ]}
             closable={false}
      >
        <Form
          form={updateChangeForm}
          name="new_config"
          className="row-col"
          onFinish={onSubmitCreateServiceConfig}
        >
          <Form.Item  id='config' name="config">
              <Editor height="50vh" defaultLanguage="json" defaultValue="{}" />
          </Form.Item>
          <Form.Item
            hidden
            id="service"
            name="service"
            initialValue={record.id}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
           :
              <Card style={{ padding: '20px' }}><Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin></Card>
      }</>
  )
}

export default ManageServiceConfigs;
